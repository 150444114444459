import { cn } from "@/lib/utils";

export const ReconfBrandLogo = ({
  className,
}: {
  readonly className: string;
}) => {
  return (
    <img
      alt="reconfigured"
      className={className}
      src="/brand/reconf_logo.png"
    />
  );
};

export const ReconfText = ({ className }: { readonly className?: string }) => {
  return <span className={cn("text-primary", className)}>reconfigured</span>;
};
